import { useRef, useState, ChangeEvent, useEffect } from "react";
import { InputProps } from "./Input";

export const useInput = (
    type: InputProps["type"],
    defaultValue: InputProps["defaultValue"],
    onChange: InputProps["onChange"],
    acceptFloat: InputProps["acceptFloat"],
    value: InputProps["value"],
    min: InputProps["min"],
    max: InputProps["max"],
    shouldSelect: InputProps["shouldSelect"],
) => {
    const ref = useRef<HTMLInputElement | null>(null);
    const initialValue = `${type === "number" ? (typeof defaultValue === "undefined" ? "" : defaultValue) : defaultValue}`;
    const [internalValue, setInternalValue] = useState(initialValue);

    function setValue(value: number | string, e: ChangeEvent<HTMLInputElement>) {
        e.target.value = `${value}`;
        onChange?.(e);
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const prevTargetValue = internalValue;
        let targetValue = e.target.value;
        const lastDigit = targetValue[targetValue.length - 1];
        const isAdding = targetValue.length > prevTargetValue.length;

        if (type !== "number") {
            setValue(e.target.value, e);
            return;
        }

        if (!acceptFloat) {
            if (!lastDigit) {
                setValue(`0`, e);
                return;
            }

            if (!/^[0-9.]+$/.test(lastDigit)) {
                return;
            }

            setValue(parseInt(e.target.value ?? 0), e);
            return;
        }

        if (typeof targetValue === "undefined" || typeof lastDigit === "undefined") {
            setInternalValue(initialValue);
            setValue(initialValue, e);
            return;
        }

        if (!/^[0-9.]+$/.test(lastDigit)) {
            return;
        }

        if (lastDigit === "." && prevTargetValue.includes(".") && isAdding) {
            return;
        }

        if (isAdding) {
            if (prevTargetValue.includes(".") && prevTargetValue.split(".")[1].length >= 2) {
                return;
            }

            if (typeof min !== "undefined" && Number(targetValue < min)) {
                return;
            }

            if (typeof max !== "undefined" && Number(targetValue > max)) {
                return;
            }
        }

        if (targetValue.length > 1 && targetValue[0] === "0" && targetValue[1] !== ".") {
            targetValue = targetValue.substring(1);
        }

        setInternalValue(targetValue);

        if (prevTargetValue.includes(".")) {
            const decimalDigits = targetValue.split(".")[1] ?? "";

            if (isAdding) {
                if (decimalDigits === "" || decimalDigits === "0") {
                    return;
                }
                setValue(targetValue, e);
            } else {
                if (prevTargetValue[prevTargetValue.length - 1] !== ".") {
                    setValue(targetValue, e);
                }
            }

            return;
        }

        setValue(targetValue, e);
    }

    function handleFocus() {
        shouldSelect && ref.current?.select();
    }

    function handleBlur() {}

    useEffect(() => {
        if (!internalValue.includes(".")) {
            setInternalValue(typeof value === "undefined" ? "" : `${value}`);
            return;
        }

        if (`${value}`.includes(".")) {
            setInternalValue(`${value}`);
        } else {
            if (internalValue.split(".")[0] !== `${value}`) {
                setInternalValue(`${value}`);
            }
        }
    }, [value]);

    useEffect(() => {
        if (!acceptFloat && type === "number" && value) {
            if (value) {
                setInternalValue(parseInt(`${value ?? 0}`).toString());
            } else {
                setInternalValue("0");
            }
        }
    }, [acceptFloat]);

    return {
        ref,
        internalValue,
        handleChange,
        handleFocus,
        handleBlur,
    };
};
