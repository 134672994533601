import { ArrowIcon } from "../../common/Icons/ArrowIcon";
import { useLanguage } from "client/contexts/LanguageProvider";
import { useToggle } from "client/hooks/utils/useToggle";
import { LanguageSelectorModal } from "./LanguageSelectorModal/LanguageSelectorModal";
import { GlobeIcon } from "components/common/Icons/GlobeIcon";
import { type TranslatedRouteSlugs } from "translatedRouteSlugs";

export const languages: Record<string, string> = {
    en: "English",
    es: "Español",
    fr: "Français",
    de: "Deutsch",
    it: "Italiano",
    pt: "Português",
    bn: "বাংলা",
    si: "සිංහල",
    nl: "Dutch",
    ja: "日本語",
    zh: "简体中文",
    ko: "한국어",
    sv: "Svenska",
    tr: "Türkçe",
    id: "Bahasa Indonesia",
    pl: "Polish",


};
export const languageCodes = Object.keys(languages);

export interface InternationalizationProps {
    url: keyof TranslatedRouteSlugs;
    urlSlugVariables?: Record<string, string>;
    onlyIconMode?: boolean;
    useToggleReturn?: ReturnType<typeof useToggle>;
}

export const Internationalization = ({
    url,
    urlSlugVariables,
    onlyIconMode,
    useToggleReturn,
}: InternationalizationProps) => {
    const { i18n } = useLanguage();
    const { isOpen, onClose: close, open } = useToggleReturn || useToggle();

    if (!i18n) return null;

    return (
        <div className="flex items-center gap-3 cursor-pointer" onClick={open}>
            <div className="flex items-center">
                <GlobeIcon />
            </div>
            {!onlyIconMode && (
                <>
                    <div>{languages[i18n.language]}</div>
                    <div className="rotate-180">
                        <ArrowIcon />
                    </div>
                </>
            )}
            <LanguageSelectorModal isOpen={isOpen} close={close} url={url} variables={urlSlugVariables} />
        </div>
    );
};
