import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { get } from "../Backend/get";
import { TaskSocket } from "./TaskSocket";

export interface TaskServiceOptions {
    handleDisconnect?: () => void;
    handleReconnect?: () => void;
    handleTaskStart?: () => void;
    handleTaskComplete: (task: Task) => void;
    handleTaskFail: (task: Task) => void;
    onOfflineLimitReach?: (task: Task) => void;
}

export class TaskService {
    private task: Task;
    private options: TaskServiceOptions;
    private isComplete = false;

    public constructor(task: Task, options: TaskServiceOptions) {
        this.task = task;
        this.options = options;
    }

    public async appendTask(shouldCheckStatus: boolean = true) {
        await this.initSocket();
        shouldCheckStatus && this.checkStatus();
    }

    private async initSocket() {
        const taskSocket = new TaskSocket();
        await taskSocket.appendTask(this.task, {
            handleDisconnect: this.options.handleDisconnect,
            handleReconnect: this.options.handleReconnect,
            handleTaskStart: this.options.handleTaskStart,
            handleTaskComplete: (task) => {
                this.handleTaskComplete(task);
            },
            handleTaskFail: this.handleTaskFail,
            onOfflineLimitReach: this.options.onOfflineLimitReach,
        });
    }

    private async checkStatus(): Promise<boolean> {
        if (this.isComplete) {
            return true;
        }
        const { result, error } = await get<Task>(`/api/tasks/${this.task.id}`);

        if (error || !result) {
            this.handleTaskFail(this.task);
            return false;
        }

        if (result.status === "completed") {
            this.options.handleTaskComplete(result);
            return true;
        }

        if (result.status === "failed") {
            this.options.handleTaskFail(result);
        }

        return false;
    }

    private handleTaskComplete = async (task: Task) => {
        if (this.isComplete) {
            return;
        }

        this.isComplete = true;
        this.options.handleTaskComplete(task);
    };

    private handleTaskFail = (task: Task) => {
        this.options.handleTaskFail(task);
    };
}
