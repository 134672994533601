
export  class RouteNames {
    public static readonly imageCompressor = "/image-compressor";
    public static readonly pngCompressor = "/png-compressor";
    public static readonly gifCompressor = "/gif-compressor";
    public static readonly jpegCompressor = "/compress-jpeg";
    public static readonly imageConverter = "/image-converter";
    public static readonly imageEnlarger = "/image-enlarger";
    public static readonly cropImage = "/crop-image";
    public static readonly rotateImage = "/rotate-image";
    public static readonly flipImage = "/flip-image";
    public static readonly bulkResize = "/bulk-resize";
    public static readonly colorPicker = "/color-picker";
    public static readonly imageResizer = "/";

}