import React from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@/components/shadcn/breadcrumb";
import { useRouter } from "next/router";
import { usePageInfo } from "@/contexts/PageInfoProvider";
import { toTitleCase } from "@/utils/reCase";
import { RouteNames } from "@/RouteNames";
import { imageConvertersSlugToNames } from "components/features/ImageConverter/ImageConverterCollections";
import translatedRoutesSlugs from "translatedRouteSlugs";
import { Translate } from "../Translate/Translate";

const IRBreadcrumb = () => {
    const router = useRouter();
    const locale = router.locale || "en";
    const pageInfo = usePageInfo();

    const imageConverterBreadcrumb = () => {
        let converterPath = router.asPath.includes(`-to-`)
            ? `${router.asPath.split("-").at(-1) || ""}-converter`
            : router.asPath;
        return (
            router.pathname === "/[format-converter]" && (
                <>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={RouteNames.imageConverter}>
                            {formatName("/image-converter")}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />

                    {pageInfo.list3 && pageInfo.list3.length > 0 && (
                        <>
                            <BreadcrumbItem>
                                <BreadcrumbLink href={locale === "en" ? converterPath : `/${locale}/${converterPath}`}>
                                    {imageConvertersSlugToNames(converterPath)}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                        </>
                    )}
                </>
            )
        );
    };

    const imageCompressorBreadcrumb = () => {
        if (
            router.pathname === RouteNames.pngCompressor ||
            router.pathname === RouteNames.jpegCompressor ||
            router.pathname === RouteNames.gifCompressor
        ) {
            return (
                <>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={translatedRoutesSlugs[RouteNames.imageCompressor][locale]}>
                            {formatName("/image-compressor")}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                </>
            );
        }
    };

    const formatName = (slug: string) => {
        return translatedRoutesSlugs[slug] ? toTitleCase(translatedRoutesSlugs[slug][locale]) : toTitleCase(slug);
    };

    if (router.pathname === "/") return null;

    return (
        <div className="px-4 md:px-10 pt-2 md:pt-4">
            <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">
                            <Translate keyName="common:home" />
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    {imageConverterBreadcrumb()}
                    {imageCompressorBreadcrumb()}
                    <BreadcrumbItem>
                        {router.pathname === "/[format-converter]" ? (
                            <BreadcrumbLink href={locale === "en"? router.asPath: `/${locale}/${router.asPath}`}>
                                {imageConvertersSlugToNames(router.asPath.replace("/", ""))}
                            </BreadcrumbLink>
                        ) : (
                            <BreadcrumbLink href={translatedRoutesSlugs[router.pathname][locale]}>
                                {formatName(router.pathname)}
                            </BreadcrumbLink>
                        )}
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>
        </div>
    );
};

export default IRBreadcrumb;
