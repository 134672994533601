import { Sort } from "store/types/sort";
import { getSortedImages } from "store/utils/getSortedImages";
import { ImageConverterState } from "../../types";

interface IPayload {
    payload: Sort | null;
}

export const setSort = (state: ImageConverterState, { payload: sort }: IPayload) => {
    state.images = getSortedImages(sort, state.images).map((_image) => {
        return state.images.find((i) => _image.id === i.id) ?? _image;
    });

    state.sort = sort;
};
