import { ImageConverterState } from "../../types";

interface IPayload {
    payload: string;
}

export const deleteImage = (state: ImageConverterState, { payload: imageId }: IPayload) => {
    state.images = state.images.filter((image) => {
        return image.id !== imageId;
    });

    state.immutableImages = state.immutableImages.filter((image) => {
        return image.id !== imageId;
    });

    if (state.images.length < 2) {
        state.settings.operation = "convert";
    }
};
