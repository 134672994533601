import { combineReducers } from "@reduxjs/toolkit";
import { bulkResizeSlice } from "./features/bulkResize/slice";
import { imageConverterSlice } from "./features/imageConverter/slice";
import { bulkResizeDownloadSlice } from "./features/bulkResizeDownload/slice";
import { colorPickerSlice } from "./features/colorPicker/slice";
import { compressSlice } from "./features/compress/slice";
import { cropSlice } from "./features/crop/slice";
import { enlargeSlice } from "./features/enlarge/slice";
import { flipRotateSlice } from "./features/flipRotate/flipRotateSlice";
import { freshworksSlice } from "./features/freshworks/slice";
import { imageUploaderSlice } from "./features/imageUploader/slice";
import { memeGeneratorSlice } from "./features/memeGenerator/memeGeneratorSlice";
import { resizeBackgroundImportSlice } from "./features/resizeBackgroundImport/resizeBackgroundImportSlice";
import { resizeDownloadSlice } from "./features/resizeDownload/resizeEditorSlice";
import { resizeEditorSlice } from "./features/resizeEditor/resizeEditorSlice";
import { toastsSlice } from "./features/toasts/slice";

export const combinedReducers = combineReducers({
    imageUploader: imageUploaderSlice.reducer,
    resizeEditor: resizeEditorSlice.reducer,
    resizeDownload: resizeDownloadSlice.reducer,
    resizeBackgroundImport: resizeBackgroundImportSlice.reducer,
    bulkResize: bulkResizeSlice.reducer,
    enlarge: enlargeSlice.reducer,
    crop: cropSlice.reducer,
    flipRotate: flipRotateSlice.reducer,
    compress: compressSlice.reducer,
    colorPicker: colorPickerSlice.reducer,
    toasts: toastsSlice.reducer,
    bulkResizeDownload: bulkResizeDownloadSlice.reducer,
    memeGenerator: memeGeneratorSlice.reducer,
    freshworks: freshworksSlice.reducer,
    imageConverter: imageConverterSlice.reducer,
});
