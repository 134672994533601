import { getSortedImages } from "store/utils/getSortedImages";
import { ImageConverterImage, ImageConverterState } from "../../types";

interface IPayload {
    payload: ImageConverterImage;
}

export const addImage = (state: ImageConverterState, { payload: _image }: IPayload) => {
    state.images.unshift(_image);
    state.images = getSortedImages<ImageConverterImage>(state.sort, state.images);
};
