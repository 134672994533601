import { ImageConverterState } from "./types";

export const initialState: ImageConverterState = {
    sort: null,
    images: [],
    immutableImages: [],
    settings: {
        operation: "convert",
    },
    lastModified: null,
    fromDownloadPage: false,
};
