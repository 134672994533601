import { setSort } from "./sort/setSort";
import { deleteImage } from "./images/deleteImage";
import { addImage } from "./images/addImage";
import { deleteAllImages } from "./images/deleteAllImages";
import { setImages } from "./images/setImages";
import { setImageState } from "./images/setImageState";
import { setImporting } from "./images/setImporting";
import { setIsCorrupted } from "./images/setIsCorrupted";
import { reset } from "./reset";
// settings
import { setOutput } from "./settings/setOutput";
// pageInfo
import { setPageInfo } from "./setPageInfo";
import { setOperation } from "./settings/setOperation";

export const reducers = {
    // filter
    setSort,
    // images
    addImage,
    deleteImage,
    deleteAllImages,
    setImages,
    setImageState,
    setImporting,
    setIsCorrupted,
    reset,
    // settings
    setOutput,
    // pageInfo
    setPageInfo,
    setOperation,
};
