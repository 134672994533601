import { useDeviceUpload } from "client/hooks/upload/useDeviceUpload";
import { useGoogleDriveUpload } from "client/hooks/upload/useGoogleDriveUpload";
import { HandleFile } from "client/types/HandleFile";
import { capitalize } from "client/utils/capitalize";
import { ArrowIcon } from "components/common/Icons/ArrowIcon";
import { SelectImageIcon } from "components/common/Icons/SelectImageIcon";
import { UrlIcon } from "components/common/Icons/UrlIcon";
import { ToolTip } from "components/common/ToolTip/ToolTip";
import { translate, Translate } from "components/common/Translate/Translate";
import { GoogleDriveAccessErrorModal } from "components/common/Upload/GoogleDriveUpload/GoogleDriveAccessErrorModal/GoogleDriveAccessErrorModal";
import { MouseEventHandler, useEffect } from "react";
import { DeviceUpload } from "../ImageUploader/DeviceUpload/DeviceUpload";
import { DropBoxUpload } from "../ImageUploader/DropBoxUpload/DropBoxUpload";
import { GoogleDriveUpload } from "../ImageUploader/GoogleDriveUpload/GoogleDriveUpload";
import { ImageUploaderProps } from "../ImageUploader/ImageUploader";
import { useUploadTypeSelector } from "./useUploadTypeSelector";
import { UploadIcon } from "lucide-react";
import { useRouter } from "next/router";
import { UploadOptions } from "@/types/UploadOptions";

interface UploadTypeSelectorProps {
    handleFile: HandleFile;
    allowMultiple: boolean;
    htmlFileInputId: string;
    analytics: ImageUploaderProps["analytics"];
    supportedExtensions: UploadOptions["supportedExtensions"];
    ignoreExtensionCheck?: UploadOptions["ignoreExtensionCheck"];
}

export const UploadTypeSelector = ({
    handleFile,
    allowMultiple,
    htmlFileInputId,
    supportedExtensions,
    analytics,
    ignoreExtensionCheck,
}: UploadTypeSelectorProps) => {
    const { isFocused, ref, toggleFocus, handleUploadTypeChange } = useUploadTypeSelector(analytics);
    const { handleClick: handleDeviceInputClick } = useDeviceUpload(
        handleFile,
        { allowMultiple, supportedExtensions },
        htmlFileInputId,
    );
    const {
        accessError,
        handleClick: handleGoogleDriveButtonClick,
        setAccessError,
        isLoading: isGoogleDriveLoading,
    } = useGoogleDriveUpload(handleFile, { allowMultiple, supportedExtensions, ignoreExtensionCheck }, isFocused);
    const pathname = useRouter().pathname;
    const enableStickyButton = pathname.includes("-converter") || pathname.includes("-to-");

    const handleSelectImageClick: MouseEventHandler = (_e) => {
        handleDeviceInputClick();
        handleUploadTypeChange("device", true);
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", function () {
                const button = document.getElementById("upload-image-sticky-button");
                const buttonHost = document.querySelector(".upload-image-sticky-button-host"); // Adjust the selector to your button's host element

                if (!button || !buttonHost) {
                    return;
                }
                if (buttonHost.getBoundingClientRect().bottom < 0) {
                    button.style.display = "flex";
                } else {
                    button.style.display = "none";
                }
            });
        }
    }, []);

    return (
        <div
            className="upload-image-sticky-button-host relative cursor-pointer flex bg-slate-50 dark:bg-darkSurface-200 rounded-md mt-8 select-none"
            ref={ref}
        >
            <button
                className="flex items-center py-5 px-6 gap-2 border-r-slate-200 dark:border-r-darkSurface-100 border-r hover:bg-irGray-200 dark:hover:bg-darkSurface-300 rounded-md "
                onClick={handleSelectImageClick}
                data-testid="device-upload-direct"
            >
                <SelectImageIcon />
                <div className="font-semibold whitespace-nowrap leading-4-5 text-black dark:text-white">
                    {allowMultiple ? (
                        <Translate keyName="uploader-select-images" />
                    ) : (
                        <Translate keyName="uploader-select-image" />
                    )}
                </div>
            </button>
            {/*sticky button*/}
            {enableStickyButton && (
                <div
                    className="w-full fixed bottom-2 left-0 items-center  flex justify-center"
                    style={{
                        zIndex: 99,
                        pointerEvents: "none",
                    }}
                >
                    <button
                        id="upload-image-sticky-button"
                        className=" w-48  px-2 py-4 flex-row items-center justify-center  gap-2 bg-irBlue-200 hover:bg-irBlue-600  rounded-md shadow-2xl "
                        onClick={handleSelectImageClick}
                        data-testid="device-upload-direct"
                        style={{
                            pointerEvents: "auto",
                            display: "none",
                        }}
                    >
                        <UploadIcon className="text-white h-5 w-5" />
                        <div className="font-semibold whitespace-nowrap leading-4-5 text-white">
                            {allowMultiple ? (
                                <Translate keyName="uploader-upload-images" />
                            ) : (
                                <Translate keyName="uploader-upload-image" />
                            )}
                        </div>
                    </button>
                </div>
            )}
            <GoogleDriveAccessErrorModal
                isOpen={accessError}
                close={() => setAccessError(false)}
                handleRetry={() => {
                    handleUploadTypeChange("googleDrive");
                    handleGoogleDriveButtonClick();
                }}
            />
            <ToolTip
                text={capitalize(isFocused ? translate("uploader-close-menu") : translate("uploader-expand-menu"))}
                position={{
                    y: "up",
                }}
            >
                <div
                    className={`rounded-md overflow-hidden flex items-center justify-center px-5 ${
                        isFocused && `bg-slate-200 dark:bg-darkSurface-200`
                    } hover:bg-irGray-200 dark:hover:bg-darkSurface-300 h-full`}
                    onClick={toggleFocus}
                >
                    {isFocused ? (
                        <div className="rotate-180" onClick={toggleFocus}>
                            <ArrowIcon width={16} height={8} />
                        </div>
                    ) : (
                        <div onClick={toggleFocus}>
                            <ArrowIcon width={16} height={8} />
                        </div>
                    )}
                </div>
            </ToolTip>
            {isFocused && (
                <div className="shadow-2xl rounded-md overflow-hidden absolute top-full left-0 right-0 text-black dark:text-white pt-2 z-10">
                    <ul className="cursor-pointer rounded-md overflow-hidden flex flex-col bg-white dark:bg-darkSurface-200">
                        <DeviceUpload
                            handleClick={() => handleUploadTypeChange("device")}
                            handleFile={handleFile}
                            allowMultiple={allowMultiple}
                            htmlFileInputId="file"
                            supportedExtensions={supportedExtensions}
                        />
                        <DropBoxUpload
                            handleClick={() => handleUploadTypeChange("dropBox")}
                            handleFile={handleFile}
                            allowMultiple={allowMultiple}
                            supportedExtensions={supportedExtensions}
                        />
                        <GoogleDriveUpload
                            isLoading={isGoogleDriveLoading}
                            handleClick={() => {
                                handleUploadTypeChange("googleDrive");
                                handleGoogleDriveButtonClick();
                            }}
                        />
                        <li
                            className="hover:bg-slate-200 dark:hover:bg-darkSurface-300 flex items-center gap-4 px-6 py-4"
                            data-testid="url-upload-selector"
                            onClick={() => handleUploadTypeChange("url")}
                        >
                            <div>
                                <UrlIcon width={16} height={16} />
                            </div>
                            <Translate keyName="from-url" />
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
