import { translateFunc } from "client/utils/translateFunc";
import { CloseIcon } from "components/common/Icons/CloseIcon";
import { Modal } from "components/common/Modal/Modal";
import { Translate } from "components/common/Translate/Translate";
import { useInvalidImageExtensionModal } from "./useInvalidImageExtensionModal";

export const InvalidImageExtensionModal = () => {
    const { isOpen, closeModal, allowedExts, invalidExts } = useInvalidImageExtensionModal();

    return (
        <Modal isOpen={isOpen} close={closeModal}>
            <div className="w-full h-screen flex items-center justify-center text-black m-4" onClick={closeModal}>
                <div
                    className="bg-white dark:bg-darkSurface-300 inline-flex flex-col items-center p-8 rounded-lg relative text-black dark:text-white"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={"text-xl font-semibold mb-8"}>
                        <Translate keyName="invalid-extension-title" />
                    </div>

                    <div>
                        <Translate
                            keyName="invalid-extension-text"
                            variables={{
                                all_extensions: invalidExts.join(", ").toUpperCase(),
                                allowed_extensions: allowedExts.join(", ").toUpperCase(),
                            }}
                        />
                    </div>

                    <button
                        className="cursor-pointer inline-block bg-irBlue-200 dark:bg-darkPrimary-500 dark:text-black dark:hover:bg-darkPrimary-400 hover:bg-irBlue-600 text-white px-16 py-2 rounded-md mt-8"
                        onClick={closeModal}
                    >
                        {translateFunc("common:ok")?.toUpperCase()}
                    </button>

                    <div className="group cursor-pointer absolute right-4 top-4" onClick={closeModal}>
                        <div className="group-hover:hidden">
                            <CloseIcon />
                        </div>
                        <div className="hidden group-hover:block">
                            <CloseIcon className="stroke-[var(--red-500)]" />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
