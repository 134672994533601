import { createContext, ReactNode, useContext, useEffect } from "react";
import { PageInfo } from "@/modules/ImageConverter/types/PageInfo";
import { useAppDispatch } from "@/store/store";
import { setPageInfo } from "@/store/features/imageConverter/actions";

interface PageInfoProviderProps {
    children: ReactNode;
    pageInfo: PageInfo;
}

export const PageInfoProviderContext = createContext<PageInfo>({
    sources: [],
    target: "",
    template: "",
    slug: "",
});

export const PageInfoProvider = ({ children, pageInfo }: PageInfoProviderProps) => {
    return <PageInfoProviderContext.Provider value={pageInfo}>{children}</PageInfoProviderContext.Provider>;
};

export const usePageInfo = () => {
    const pageInfo = useContext(PageInfoProviderContext);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setPageInfo(pageInfo));
    }, []);
    return pageInfo;
};
