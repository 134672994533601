import React, {useContext} from "react";
import {PageInfoProviderContext} from "@/contexts/PageInfoProvider";
import {useRouter} from "next/router";
import {toTitleCase} from "@/utils/reCase";
import Link from "@/components/common/Link";
import {translateFunc} from "@/utils/translateFunc";
import {Translate} from "components/common/Translate/Translate";

const ImageConverterCollection = () => {
    const router = useRouter();
    const pageInfo = useContext(PageInfoProviderContext);
    // console.log(pageInfo);
    const list1 = pageInfo.list1 ?? [];
    const list2 = pageInfo.list2 ?? [];
    const list3 = pageInfo.list3 ?? [];

    if (list1.length === 0 && list2.length === 0 && list3.length === 0) {
        return <> </>;
    }

    const toolPath = router.asPath.split("/")[1];
    let format = "X";
    let format2 = "Y";
    if (toolPath.includes("-converter") && !toolPath.includes("image-converter")) {
        format = toolPath.replace("-converter", "").toUpperCase();
        format2 = format;
    } else if (toolPath.includes("-to-")) {
        format = toolPath.split("-to-")[0].toUpperCase();
        format2 = toolPath.split("-to-")[1].toUpperCase();
    }

    const buildGridView = (list: any[], subtitle: string) => {
        return (
            <div className="w-full text-center">
                <h2 className="font-semibold text-base">{subtitle}</h2>
                <div className="mt-10 w-full  grid grid-cols-4 gap-8 justify-center items-center">
                    {list.map((item, index) => {
                        return (
                            <Link
                                href={`/${item}`}
                                key={index}
                                className={`border-irGray-600 border-[1px] h-16 w-full flex items-center justify-center rounded-lg
                                 text-irBlue-600 dark:text-irBlue-200 hover:bg-gray-100 hover:dark:bg-gray-700 text-xs md:text-base`}
                            >
                                <span className="px-2">{imageConvertersSlugToNames(item)}</span>
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    };
    return (
        <div className="flex flex-col justify-center items-center w-full mt-24 md:mt-[150px] dark:text-white gap-3">
            <h2 className="font-semibold text-2xl">
                <Translate keyName="image-converter-collection-title" variables={{format}}/>
            </h2>

            <div className="flex flex-col gap-[72px]">
                {list1.length > 0 &&
                    buildGridView(list1, translateFunc("image-converter-collection-sub-title-1", {format}))}
                {list2.length > 0 &&
                    buildGridView(list2, translateFunc("image-converter-collection-sub-title-2", {format: format2}))}
                {list3.length > 0 &&
                    buildGridView(list3, translateFunc("image-converter-collection-sub-title-3",))}
            </div>
        </div>
    );
};

export function imageConvertersSlugToNames(slug: string) {
    if (slug.includes("-to-")) {
        const src = slug.split("-to-")[0];
        const target = slug.split("-to-")[1];
        return translateFunc('src-to-target', {src: src.toUpperCase(), target: target.toUpperCase()});
    } else if (slug.includes("-converter")) {
        const format = slug.replace("-converter", "");
        return translateFunc('format-converter', {format: format.toUpperCase()});
    }
    return toTitleCase(slug).toUpperCase();
}

export default ImageConverterCollection;
