import { ImageConverterImage, ImageConverterState } from "../../types";

interface Payload {
    payload: ImageConverterImage[];
}

export const setImages = (state: ImageConverterState, { payload: _images }: Payload) => {
    state.images = _images;
    state.immutableImages = [...state.images];
    state.fromDownloadPage = true;
};
