import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import { AnchorHTMLAttributes } from "react";
import translatedRouteSlugs from "../../../../translatedRouteSlugs";

type LinkProps = NextLinkProps & AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = (props: LinkProps) => {
    const router = useRouter();
    const locale = router.locale || "/en";
    let link = props.href?.toString() || "";

    if (
        !link.includes("http") &&
        !link.includes("meme-generator") &&
        (link.includes("-converter") || link.includes("-to-") || isImageConverterRoute(link, locale))
    ) {
        if (locale !== "en" && !link.startsWith(`/${locale}/`)) {
            link = `/${locale}${link[0] === "/" ? link : `/${link}`}`;
        }
        return (
            <a {...props} href={link}>
                {props.children}
            </a>
        );
    }
    return <NextLink {...props}>{props.children}</NextLink>;
};

export default Link;

const isImageConverterRoute = (link: string, locale: string): boolean => {
    return translatedRouteSlugs["/image-converter"]?.[locale ?? "en"] === link.replace("/", "");
}