import type { Job, Task } from "@freeconvert/freeconvert-node/dist/types";
import { type ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { type IPostReturn as PostReturn, post } from "client/services/Backend/post";
import type { OptimizationLevel } from "store/features/bulkResize/types";
import type { ResizeEditorState } from "../types";

export interface ResizeJobPayload {
    crop: {
        inputTask: Task;
        inputFormat?: string;
        outputFormat: string;
        options: {
            x: number;
            y: number;
            width: number;
            height: number;
        };
    } | null;
    resize: {
        inputFormat?: string;
        outputFormat: string;
        resize_as: "dimensions" | "percentage";
        dimensions: {
            width: number;
            height: number;
        } | null;
        percentage: number | null;
        options: {
            verticalFlip: boolean;
            horizontalFlip: boolean;
            dpi?: number;
            rotation: number;
            maxFileSize?: number;
            background?: string;
            fillBackground?: boolean;
        };
        optimization_level?: "best" | "good" | "average";
    };
    importTask: Task;
    tag?: string;
    extra?: ResizeJobExtraData;
}

export interface ResizeJobExtraData {
    original_width: number;
    original_height: number;
    file_name: string;
    new_width: number;
    new_height: number;
}

export const createResizeJob = createAsyncThunk(
    "resizeEditor/createResizeJob",
    async ({ crop, resize, importTask, tag, extra }: ResizeJobPayload): Promise<PostReturn<Job>> => {
        let resizeData: any = {};
        let resizeOptions: any = {
            max_file_size: resize.options.maxFileSize || undefined,
            background_color: resize.options.background,
            fill_background:
                typeof resize.options.fillBackground === "undefined" ? false : resize.options.fillBackground,
            image_dpi: resize.options.dpi,
            "auto-orient": true,
            strip: true,
        };

        resizeOptions = {
            image_rotate: resize.options.rotation,
            image_vertical_flip: resize.options.verticalFlip,
            image_horizontal_flip: resize.options.horizontalFlip,
            ...resizeOptions,
        };

        if (resize.resize_as === "percentage") {
            resizeOptions = {
                ...resizeOptions,
                image_resize_percentage: resize.percentage,
            };
        } else {
            resizeOptions = {
                ...resizeOptions,
                image_custom_width: resize.dimensions?.width ? Math.floor(resize.dimensions.width) : undefined,
                image_custom_height: resize.dimensions?.height ? Math.floor(resize.dimensions.height) : undefined,
            };
        }

        if (resize.outputFormat === "png") {
            resizeOptions = {
                ...resizeOptions,
                png_convert_quality: 100,
                png_compression_level: "lossy",
            };
        }

        if (resize.optimization_level) {
            resizeOptions = {
                ...resizeOptions,
                ...getImageQuality(resize.optimization_level, (resize.outputFormat ?? "jpg") as OutputFormat),
            };
        }

        if (resize.resize_as === "percentage") {
            resizeData["percentage"] = resize.percentage;
        } else if (resize.resize_as === "dimensions") {
            resizeData["dimensions"] = resize.dimensions;
        }

        resizeData = {
            ...resizeData,
            operation: "convert",
            input: crop ? "crop" : importTask.id,
            input_format: resize.inputFormat?.toLowerCase(),
            output_format: resize.outputFormat?.toLowerCase(),
            resize_as: resize.resize_as,
            options: resizeOptions,
        };

        const exportTask: any = {
            operation: "export/url",
            input: "resize",
        };

        if (extra) exportTask.extra = extra;

        if (crop) {
            const cropData = {
                operation: "convert",
                input: crop.inputTask.id,
                input_format: crop.inputFormat?.toLowerCase(),
                output_format: crop.outputFormat?.toLowerCase(),
                options: {
                    crop_x_coordinates: crop.options.x,
                    crop_y_coordinates: crop.options.y,
                    image_crop_target_width: crop.options.width,
                    image_crop_target_height: crop.options.height,
                },
            };
            const result = await post<Job>("/api/jobs", {
                tag: tag || "resize-editor-conversion",
                tasks: {
                    crop: cropData,
                    resize: resizeData,
                    exportTask,
                },
            });

            // this is so that redux toolkit handles this as rejected
            if (result.error) {
                throw {
                    error: result.error,
                };
            }

            return result;
        } else {
            const result = await post<Job>("/api/jobs", {
                tag: tag || "resize-editor-conversion",
                tasks: {
                    resize: resizeData,
                    exportTask,
                },
            });

            // this is so that redux toolkit handles this as rejected
            if (result.error) {
                throw {
                    error: result.error,
                };
            }

            return result;
        }
    },
);

export const handleCreateResizeJob = (_builder: ActionReducerMapBuilder<ResizeEditorState>) => {};

type OutputFormat = "png" | "jpg" | "jpeg" | "gif";

const getImageQuality = (
    optimization_level: Omit<OptimizationLevel, "best" | "custom">,
    output_format: OutputFormat,
) => {
    switch (optimization_level) {
        case "best": {
            if (output_format === "png") return { png_compression_quality: 92 };
            else if (output_format === "jpg" || output_format === "jpeg") return { jpeg_compress_image_quality: 92 };
            else if (output_format === "gif") return { gif_compression_level: 92 };
        }
        case "good": {
            if (output_format === "png") return { png_compression_quality: 70 };
            else if (output_format === "jpg" || output_format === "jpeg") return { jpeg_compress_image_quality: 70 };
            else if (output_format === "gif") return { gif_compression_level: 70 };
        }
        case "average": {
            if (output_format === "png") return { png_compression_quality: 55 };
            else if (output_format === "jpg" || output_format === "jpeg") return { jpeg_compress_image_quality: 55 };
            else if (output_format === "gif") return { gif_compression_level: 55 };
        }
        default: {
            break;
        }
    }
};
