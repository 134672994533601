import { imageConverterSlice } from "./slice";

export const {
    // filter
    setSort,
    // images
    addImage,
    deleteImage,
    deleteAllImages,
    setImages,
    setImageState,
    setImporting,
    setIsCorrupted,
    reset,
    // settings
    setOutput,
    // pageInfo
    setPageInfo,
    setOperation,
} = imageConverterSlice.actions;
