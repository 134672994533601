import { ImageConverterState } from "../../types";

interface Payload {
    payload: ImageConverterState["settings"]["output"];
}

export const setOutput = (state: ImageConverterState, { payload }: Payload) => {
    state.settings.output = payload;
    state.settings.operation = "convert";
};
